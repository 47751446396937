import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { isLoggedIn } from "@/services/auth";
import { moduleNames } from "@/services/permissions";

function requireAuth(to: any, from: any, next: any) {
  if (isLoggedIn()) {
    next();
  } else {
    next({
      name: "login",
      // query: { redirect: to.fullPath }
    });
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/home",
    name: "home",
    component: () => import("../views/dashboard/home/ReDevelopedHome.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: [],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/jobbase",
    name: "jobbase",
    component: () => import("../views/dashboard/jobbase/index.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Jobbase"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/add-jobbase",
    name: "addJobbase",
    component: () => import("../views/dashboard/jobbase/AddJobBase.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Jobbase"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/edit-jobbase",
    name: "editJobbase",
    component: () => import("../views/dashboard/jobbase/EditJobBase.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Jobbase"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/dashboard/profile/Profile.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: [],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/view-flight-request-details",
    name: "flightRequestDetails",
    component: () =>
      import(
        "../views/dashboard/accounting/flight-requests/EmployeePreviewAndSign.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: [],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/leaves",
    name: "leaves",
    component: () => import("../views/dashboard/employees/HrLeave.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Leave"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/experts",
    name: "experts",
    component: () => import("../views/dashboard/experts/Experts.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Addresses"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/entities",
    name: "entities",
    component: () => import("../views/dashboard/entites/Entities.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Addresses"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/add-entity",
    name: "addEntity",
    component: () => import("../views/dashboard/entites/AddEntity.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Addresses"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/edit-mission-forms",
    name: "missionForms",
    component: () =>
      import(
        "@/components/dashboard/projects/mission-forms/CreateEditForms.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Order"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/edit-order",
    name: "editOrder",
    component: () =>
      import("@/components/dashboard/projects/ViewEditProjectOrder.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Order"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/contract-addenda",
    name: "contractAddenda",
    component: () =>
      import("@/components/dashboard/projects/contracts/contractAddenda/ContractAddenda.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Contracts"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/edit-employment-contract",
    name: "editEmploymentContract",
    component: () =>
      import(
        "@/components/dashboard/projects/employment-settlement/EmploymentContractTabs.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Order"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/edit-entity",
    name: "editEntity",
    component: () => import("../views/dashboard/entites/AddEntity.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Addresses"],
      isEdit: true,
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/foreign-employees",
    name: "foreignEmployees",
    component: () =>
      import("@/views/dashboard/foreign-employees/ForeignEmployees.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Addresses"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/add-foreign-employee",
    name: "addForeignEmployee",
    component: () =>
      import("@/views/dashboard/foreign-employees/AddForeignEmployee.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Addresses"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/expert-contract-approval/:type",
    name: "expertcontractapproval",
    component: () =>
      import(
        "@/views/dashboard/projects/projects/NewContractPreview.vue"
      ),
    meta: {
      allowAnonymous: true,
      layout: "blank",
      permissions: [],
      isExpertSignature: true
    },
  },
  {
    path: "/edit-foreign-employee",
    name: "editForeignEmployee",
    component: () =>
      import("@/views/dashboard/foreign-employees/EditForeignEmployee.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Addresses"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/foreign-office",
    name: "foreignOffice",
    component: () =>
      import("@/views/dashboard/foreign-office/ForeignOffices.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Addresses"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/add-foreign-office",
    name: "addForeignOffice",
    component: () =>
      import("@/views/dashboard/foreign-office/AddForeignOffice.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Addresses"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/edit-foreign-office",
    name: "editForeignOffice",
    component: () =>
      import("@/views/dashboard/foreign-office/EditForeignOffice.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Addresses"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/employees",
    name: "employees",
    component: () => import("../views/dashboard/employees/Employees.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Human Resource"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/edit-employee",
    name: "editEmployee",
    component: () => import("../views/dashboard/employees/EditEmployee.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Human Resource"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/announcements",
    name: "Announcements",
    component: () => import("@/views/dashboard/employees/MyAnnouncements.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Human Resource"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/maternity-parental-leaves",
    name: "maternityParentalLeaves",
    component: () =>
      import(
        "@/components/dashboard/maternityParentalLeaves/MaternityParentalLeaves.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Human Resource"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/entertainments",
    name: "hospitality",
    component: () => import("@/views/dashboard/hospitality/Hospitality.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Billing"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/add-entertainment-request",
    name: "addEntertainmentRequest",
    component: () =>
      import("@/views/dashboard/hospitality/AddHospitalityPackage.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Billing"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/edit-entertainment-request",
    name: "editEntertainmentRequest",
    component: () =>
      import("@/views/dashboard/hospitality/EditHospitalityPackage.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Billing"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/profile-add-entertainment-request",
    name: "profileAddEntertainmentRequest",
    component: () =>
      import("@/views/dashboard/hospitality/AddHospitalityPackage.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Profile"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/view-entertainment-request-details",
    name: "entertainmentRequestDetails",
    component: () =>
      import("@/views/dashboard/hospitality/EmployeeRequestDetails.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Profile"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/edit-expert",
    name: "editExpert",
    component: () => import("../views/dashboard/experts/EditExpert.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Addresses"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/edit-partner",
    name: "editPartner",
    component: () => import("../views/dashboard/partners/EditPartner.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Addresses"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/add-employee",
    name: "addEmployee",
    component: () => import("../views/dashboard/employees/AddEmployee.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Human Resource"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/add-expert",
    name: "addExpert",
    component: () => import("../views/dashboard/experts/AddExpert.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Addresses"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/add-partner",
    name: "addPartner",
    component: () => import("../views/dashboard/partners/AddPartner.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Addresses"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/add-project",
    name: "addProject",
    component: () =>
      import("../views/dashboard/projects/projects/AddProject.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Projects"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/cost-center",
    name: "costCenter",
    component: () =>
      import("@/components/dashboard/hr-settings/CostCenters.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Projects"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/add-cost-center",
    name: "addCostCenter",
    component: () => import("../views/dashboard/cost-center/CostCenter.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Projects"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/preview-print",
    name: "previewPrint",
    component: () =>
      import("../views/dashboard/projects/projects/PrintPreview.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Projects"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/edit-project",
    name: "editProject",
    component: () =>
      import("../views/dashboard/projects/projects/EditProject.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Projects"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/mission-invoice",
    name: "missionInvoice",
    component: () => import("../views/dashboard/projects/MissionInvoice.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Projects"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/mission-settlement",
    name: "missionSettlement",
    component: () =>
      import("../views/dashboard/projects/MissionSettlement.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Projects"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/contract-approval/:type",
    name: "contract-approval",
    component: () =>
      import("@/views/dashboard/projects/projects/NewContractPreview.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Projects"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/deployment-agreement",
    name: "deployment-agreement",
    component: () =>
      import("../views/dashboard/projects/projects/DeploymentAgreement.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Projects"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/projects",
    name: "projects",
    component: () =>
      import("../views/dashboard/projects/projects/Projects.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Projects"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/flight-overview",
    name: "flightOverview",
    component: () =>
      import("@/components/dashboard/flight-mangement/FlightOverview.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Flight Management"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/flight-settlement",
    name: "flightSettlement",
    component: () =>
      import("@/components/dashboard/flight-mangement/FlightSettlement.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Flight Management"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/open-flight",
    name: "openFlight",
    component: () =>
      import("@/components/dashboard/flight-mangement/OpenFlights.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Flight Management"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/single-flight-settlement",
    name: "singleFlightSettlement",
    component: () =>
      import(
        "@/components/dashboard/flight-mangement/SingleFlightSettlement.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Flight Management"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/references",
    name: "references",
    component: () =>
      import("@/views/dashboard/projects/reference/ReferenceView.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["References"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/pm-locals",
    name: "pmLocals",
    component: () =>
      import("@/views/dashboard/projects/pm-locals/PmLocals.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Projects"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/edit-pm-local",
    name: "editPmLocal",
    component: () =>
      import("@/views/dashboard/projects/pm-locals/EditPmLocal.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Projects"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/project-result-balance-sheet",
    name: "projectResultBalanceSheet",
    component: () =>
      import("@/components/dashboard/projects/ProjectResultBalanceSheet.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Projects"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/sub-contract-and-manual-expenses",
    name: "subContractAndManualExpenses",
    component: () =>
      import(
        "@/views/dashboard/projects/sanctioned-expenses/SanctionedExpenses.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Billing"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/add-partner-contract-and-other-invoices",
    name: "addPartnerContractAndInvoices",
    component: () =>
      import(
        "@/views/dashboard/projects/sanctioned-expenses/AddPartnerContractAndInvoices.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Billing"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/edit-partner-contract-other-invoices",
    name: "editPartnerContractAndInvoices",
    component: () =>
      import(
        "@/views/dashboard/projects/sanctioned-expenses/EditPartnerContractAndInvoices.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Billing"],
      isEdit: true,
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/view-establishment-details",
    name: "singleEstablishment",
    component: () =>
      import("@/components/dashboard/projects/SingleEstablishment.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Projects"],
      isEdit: true,
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/incoming-invoices",
    name: "incomingInvoiceOverview",
    component: () =>
      import("@/views/dashboard/incomingInvoices/IncomingInvoiceOverview.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Accounting"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/outgoing-invoices",
    name: "OutgoingInvoicesOverview",
    component: () =>
      import("@/views/dashboard/outgoingInvoices/OutgoingInvoicesOverview.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Accounting"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/interim-payments",
    name: "InterimPaymentsOverview",
    component: () =>
      import("@/views/dashboard/interimPayments/InterimPaymentsOverview.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Accounting"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/edit-interim-payments",
    name: "editInterimPayments",
    component: () =>
      import("@/views/dashboard/interimPayments/EditInterimPayment.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Accounting"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/currency-purchase",
    name: "currencyPurchase",
    component: () => import("@/views/dashboard/currency/CurrencyPurchase.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Accounting"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/currency-consumption",
    name: "currencyConsumption",
    component: () =>
      import("@/views/dashboard/currency/CurrencyConsumption.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Accounting"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/currency-exchange",
    name: "currencyExchange",
    component: () => import("@/views/dashboard/currency/CurrencyExchange.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Accounting"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/bank-account",
    name: "bankAccount",
    component: () => import("../views/dashboard/bankAccount/BankAccount.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Accounting"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/bank-account-balance",
    name: "bankAccountBalance",
    component: () =>
      import("@/views/dashboard/bankAccount/BankAccountBalance.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Accounting"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/edit-advance-payment-client",
    name: "editAdvancePaymentClient",
    component: () =>
      import(
        "@/views/dashboard/client-advance-payment/EditClientAdvancePayment.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Accounting"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/guarantees",
    name: "guarantee",
    component: () => import("@/views/dashboard/guarantee/Guarantee.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Accounting"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/account-balance-overview",
    name: "accountBalanceOverview",
    component: () =>
      import(
        "@/views/dashboard/accountBalanceOverview/AccountBalanceOverview.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Accounting"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/travelling-expenses",
    name: "travellingExpenses",
    component: () =>
      import("@/components/dashboard/hr-settings/TravellingExpense.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Accounting"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/account-cash-bank",
    name: "accountCashBalance",
    component: () =>
      import("@/views/dashboard/cash-balance/AccountCashBalance.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Accounting"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/advance-payments",
    name: "advancePayments",
    component: () =>
      import(
        "@/views/dashboard/accounting/advance-payments/AdvancePayment.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Billing"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/add-payment-request",
    name: "addPaymentRequest",
    component: () =>
      import(
        "@/views/dashboard/accounting/advance-payments/AddPaymentRequest.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Billing"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/edit-payment-request",
    name: "editPaymentRequest",
    component: () =>
      import(
        "@/views/dashboard/accounting/advance-payments/EditAdvancePaymentRequest.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Billing"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/view-advance-payments",
    name: "viewAdvancePayments",
    component: () =>
      import(
        "@/views/dashboard/accounting/advance-payments/EmployeeRequestDetails.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Profile"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/profile-add-payment-request",
    name: "profileAddPaymentRequest",
    component: () =>
      import(
        "@/views/dashboard/accounting/advance-payments/AddPaymentRequest.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Profile"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/profile-add-flight-request",
    name: "profileAddFlightRequest",
    component: () =>
      import(
        "@/views/dashboard/accounting/flight-requests/AddFlightPackage.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Profile"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/flight",
    name: "flight",
    component: () =>
      import("@/views/dashboard/accounting/flight-requests/Flight.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Billing"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/add-flight",
    name: "add-flight",
    component: () =>
      import(
        "@/views/dashboard/accounting/flight-requests/AddFlightPackage.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Billing"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/edit-flight",
    name: "edit-flight",
    component: () =>
      import(
        "@/views/dashboard/accounting/flight-requests/EditFlightPackage.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Billing"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/view-internal-mission",
    name: "viewInternalMission",
    component: () =>
      import(
        "@/views/dashboard/hr-resources/internal-mission/EmployeeInternalMission.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Profile"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/internal-mission-plans",
    name: "internalMissionPlans",
    component: () =>
      import(
        "@/views/dashboard/hr-resources/internal-mission/InternalMissionPlans.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Billing"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/profile-add-internal-mission-plan",
    name: "profileAddInternalMissionPlan",
    component: () =>
      import(
        "@/views/dashboard/hr-resources/internal-mission/AddInternalMissionPlan.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Profile"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/add-internal-mission-plan",
    name: "addInternalMissionPlan",
    component: () =>
      import(
        "@/views/dashboard/hr-resources/internal-mission/AddInternalMissionPlan.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Billing"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/edit-internal-mission-plan",
    name: "editInternalMissionPlan",
    component: () =>
      import(
        "@/views/dashboard/hr-resources/internal-mission/EditInternalMissionPlan.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Billing"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/accruals",
    name: "accruals",
    component: () =>
      import("@/views/dashboard/accounting/accruals/Accruals.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Accounting"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/accruals-mirror",
    name: "accrualsMirror",
    component: () =>
      import("@/views/dashboard/accounting/accruals/AccrualsMirror.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Accounting"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/hr-configuration",
    name: "HRConfiguration",
    component: () => import("@/views/dashboard/settings/HRConfiguration.vue"),
    meta: {
      allowAnonymous: true,
      layout: "dashboard",
      permissions: ["Human Resource"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/project-configuration",
    name: "ProjectConfiguration",
    component: () =>
      import("../views/dashboard/settings/ProjectConfiguration.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Projects"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/add-terms-and-conditions",
    name: "addTermsAndConditions",
    component: () =>
      import("@/components/dashboard/hr-settings/AddTermsAndConditions.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Projects"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/all-foreign-establishment",
    name: "foreignEstablishmentModule",
    component: () =>
      import(
        "@/views/dashboard/foreignEstablishment/ForeignEstablishmentModule.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Projects"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/access-role",
    name: "access-role",
    component: () => import("@/views/dashboard/settings/AccessRole.vue"),
    meta: {
      allowAnonymous: true,
      layout: "dashboard",
      permissions: ["Human Resource"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/access-settings",
    name: "access-settings",
    component: () => import("@/views/dashboard/settings/AddCustomRole.vue"),
    meta: {
      allowAnonymous: true,
      layout: "dashboard",
      permissions: ["Human Resource"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/view-employee-project-hours-report",
    name: "viewEmployeeProjectHoursReport",
    component: () =>
      import(
        "@/components/dashboard/reporting/hr/ViewEmployeeProjectHoursReport.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Reporting"],
    },
  },
  {
    path: "/human-resources-reporting",
    name: "humanResourcesReporting",
    component: () =>
      import("@/components/dashboard/reporting/hr/HumanResourceReporting.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Reporting"],
    },
  },
  {
    path: "/accounting-reporting",
    name: "accountingReporting",
    component: () =>
      import(
        "@/components/dashboard/reporting/accounting/AccountingReporting.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Reporting"],
    },
  },
  {
    path: "/controlling-reporting",
    name: "controllingReporting",
    component: () =>
      import(
        "@/components/dashboard/reporting/controlling/ControllingReporting.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Reporting"],
    },
  },
  {
    path: "/planing-reporting",
    name: "planingReporting",
    component: () =>
      import(
        "@/components/dashboard/reporting/controlling/StatusQuoReports.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Reporting"],
    },
  },
  {
    path: "/contract-and-order-reporting",
    name: "contractAndOrder",
    component: () =>
      import(
        "@/components/dashboard/reporting/contractAndOrder/ContractAndOrderReporting.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Reporting"],
    },
  },
  {
    path: "/proposal-reporting",
    name: "proposalReporting",
    component: () =>
      import("@/components/dashboard/reporting/proposal/ProposalReporting.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Reporting"],
    },
  },
  {
    path: "/overview-report",
    name: "overReport",
    component: () =>
      import(
        "@/components/dashboard/reporting/controlling/CostOverviewReport.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Reporting"],
    },
  },
  {
    path: "/cash-overview-reporting",
    name: "overviewReporting",
    component: () =>
      import(
        "@/components/dashboard/reporting/controlling/OverviewReporting.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Reporting"],
    },
  },
  {
    path: "/bank-guarantee-report",
    name: "bankGuaranteeOverviewReport",
    component: () =>
      import(
        "@/components/dashboard/reporting/controlling/BankGuaranteeOverviewReport.vue"
      ),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Reporting"],
    },
  },
  {
    path: "/edit-project-reference",
    name: "editProjectReference",
    component: () =>
      import("../views/dashboard/projects/reference/EditReference.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["References"],
    },
  },
  {
    path: "/generate-file",
    name: "generateFile",
    component: () =>
      import("@/components/dashboard/references/GenerateFile.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["References"],
    },
  },
  {
    path: "/reference-review",
    name: "referenceReview",
    component: () =>
      import("@/components/dashboard/references/ReferenceReview.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["References"],
    },
  },
  // Your Space Routes
  {
    path: "/your-time-off",
    name: "yourTimeOff",
    component: () =>
      import("@/components/dashboard/employees/ProfileTimeOff.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Profile"],
    },
  },
  {
    path: "/your-project-hours",
    name: "yourProjectHours",
    component: () =>
      import("@/components/dashboard/employees/project-hours/ProjectHours.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Profile"],
    },
  },
  {
    path: "/your-advance-payment",
    name: "yourAdvancePayment",
    component: () =>
      import("@/views/dashboard/employees/EmployeeAdvancePayments.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Profile"],
    },
  },
  {
    path: "/your-entertainment-requests",
    name: "yourEntertainmentRequests",
    component: () =>
      import("@/views/dashboard/employees/EmployeeEntertainmentRequest.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Profile"],
    },
  },
  {
    path: "/your-flight-requests",
    name: "yourFlightRequests",
    component: () =>
      import("@/views/dashboard/employees/EmployeeFlightRequests.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Profile"],
    },
  },
  {
    path: "/your-internal-missions",
    name: "yourInternalMissions",
    component: () =>
      import("@/views/dashboard/employees/EmployeeInternalMission.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Profile"],
    },
  },
  {
    path: "/break-even",
    name: "breakEven",
    component: () => import("@/components/dashboard/contolling/BreakEven.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Controlling"],
    },
  },
  {
    path: "/detailed-forecast-view/:type",
    name: "DetailForecastView",
    component: () => import("@/components/dashboard/reporting/controlling/forecast/DetailedForecastView.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: [],
    },
  },
  {
    path: "/project-manager-details",
    name: "ProjectManagerDetails",
    component: () => import("@/components/dashboard/reporting/controlling/DetailedProjectManagerView.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Evaluation of entire operation"],
    },
  },
  {
    path: "/",
    name: "login",
    component: () =>
      import("@/components/authentication/Login.vue"),
    meta: {
      allowAnonymous: true,
      layout: "blank",
      permissions: [],
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () =>
      import("@/components/authentication/ForgotPassword.vue"),
    meta: {
      allowAnonymous: true,
      layout: "blank",
      permissions: [],
    },
  },
  {
    path: "/access-denied",
    name: "accessDenied",
    component: () => import("@/components/authentication/AccessDenied.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: [],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () =>
      import("@/components/authentication/ResetPassword.vue"),
    meta: {
      allowAnonymous: true,
      layout: "blank",
      permissions: [],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const allowAnonymous: any = to.meta.allowAnonymous;
  const requiredPermissions: any = to.meta.permissions;
  if (allowAnonymous) {
    // Allow access to routes that don't require authentication
    next();
  } else if (isLoggedIn()) {
    const userPermissions: string[] = moduleNames.value;
    if (!requiredPermissions || !requiredPermissions.length) {
      // If there are no permissions required for this route, proceed
      next();
    } else if (
      userPermissions &&
      requiredPermissions?.every((permission: string) =>
        userPermissions?.includes(permission)
      )
    ) {
      // If the user has all required permissions, proceed
      next();
    } else {
      // If the user doesn't have the required permissions, redirect to an "access denied" route
      next({
        name: "accessDenied", // Adjust this to whatever your access denied route name is
      });
    }
  } else {
    // If the user isn't logged in, redirect to login page
    next(
      to.name === "expertcontractapproval"
        ? { name: "expertcontractapproval" }
        : { name: "login" }
    );
  }
});

export default router;
