import { createStore } from "vuex";
import auth from "./modules/auth";
import hrConfigurations from "./modules/hrConfigurations";
import translation from "./modules/translation";
import hrResources from "./modules/hrResources";
import commonResources from "./modules/commonResources";
import expertModules from "./modules/expertModules";
import projectModules from "./modules/projectModules";
import partnerModules from "./modules/partnerModule";
import projectCalculationsModule from "./modules/projectCalculationsModule";
import projectBudgetModules from "./modules/projectBudgetModules";
import accessRoleModule from "./modules/accessRoleModule";
import projectConfigurations from "./modules/projectConfigurations";
import contractModules from "./modules/contractModules";
import foreignOfficeModule from "./modules/foreignOfficeModule";
import projectLocalBudgetModule from "./modules/projectLocalBudgetModule";
import pmLocalModules from "./modules/pmLocalModules";
import projectOverview from "./modules/projectOverview";
import chequeModules from "./modules/chequeModules";
import pmLocalRequestFund from "./modules/pmLocalRequestFund";
import expenseModules from "./modules/expenseModules";
import pmLocalApproveFund from "./modules/pmLocalApproveFund";
import hospitalityModules from "./modules/hospitalityModules";
import advancePaymentModules from "./modules/advancePaymentModules";
import flightModule from "./modules/flightModule";
import invoiceModule from "./modules/invoiceModule";
import internalMissionPlanModules from "./modules/internalMissionPlanModules";
import sanctionedExpensesModule from "@/store/modules/sanctionedExpensesModule";
import outgoingInvoiceModule from "./modules/outgoingInvoiceModule";
import interimPaymentModule from "./modules/interimPaymentModule";
import manualInvoiceModule from "@/store/modules/manualInvoiceModule";
import projectHours from "@/store/modules/projectHours";
import accrualModules from "@/store/modules/accrualModules";
import reportingModule from "@/store/modules/reportingModule";
import accountNumberModules from "./modules/accountNumberModules";
import clientAdvancePaymentModule from "./modules/clientAdvancePaymentModule";
import entitiesModule from "./modules/entitiesModule";
import encryptPersistStore from "@/services/encryptPersistStore";
import projectReferencesModule from "./modules/projectReferencesModule";
import specificExperienceModule from "@/store/modules/specificExperienceModule";
import bankAccountModule from "@/store/modules/bankAccountModule";
import flightManagementModule from "@/store/modules/flightManagementModule";
// import accReportingModule from "./modules/accReportingModule";
import breakEvenModule from "./modules/breakEvenModule";
import calculationBudget from "@/store/modules/projectCalculationBudget";
import projectOrdersModule from "./modules/projectOrdersModule";
import jobbaseModule from "./modules/jobbaseModule";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    commonResources,
    projectModules,
    expertModules,
    partnerModules,
    hrResources,
    auth,
    hrConfigurations,
    translation,
    projectCalculationsModule,
    projectBudgetModules,
    accessRoleModule,
    projectConfigurations,
    contractModules,
    foreignOfficeModule,
    projectLocalBudgetModule,
    pmLocalModules,
    projectOverview,
    chequeModules,
    pmLocalRequestFund,
    expenseModules,
    pmLocalApproveFund,
    hospitalityModules,
    advancePaymentModules,
    flightModule,
    invoiceModule,
    internalMissionPlanModules,
    sanctionedExpensesModule,
    outgoingInvoiceModule,
    interimPaymentModule,
    manualInvoiceModule,
    accountNumberModules,
    projectHours,
    accrualModules,
    clientAdvancePaymentModule,
    reportingModule,
    entitiesModule,
    projectReferencesModule,
    specificExperienceModule,
    bankAccountModule,
    flightManagementModule,
    // accReportingModule,
    breakEvenModule,
    calculationBudget,
    projectOrdersModule,
    jobbaseModule 
  },
  plugins: [encryptPersistStore],
  //  plugins: [createPersistedState()],
});
